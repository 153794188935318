<template>
    <div class="main-container">
      <h5 class="pageTitle">교관정보</h5>
  
      <mdb-container class="search-container">
        <mdb-row class="search-row">
          <mdb-col class="search-col">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="교관명 검색"
              class="search-input"
              @input="filterResults"
            />
            <button class="btn-search" @click="filterResults">검색</button>
            <button class="btn-register" @click="goToRegister">등록</button>
          </mdb-col>
        </mdb-row>
      </mdb-container>
  
      <table class="table-container">
        <thead>
          <tr>
            <th>NO.</th>
            <th>이름</th>
            <th>자격번호</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedData.length === 0">
            <td colspan="3">데이터가 없습니다.</td>
          </tr>
          <tr
            v-for="(item, index) in paginatedData"
            :key="item.uuid"
            class="clickable-row"
            @click="goToUpdate(item)"
          >
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.no }}</td>
          </tr>
        </tbody>
      </table>
  
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ pageCount }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  //import Swal from "sweetalert2";
  import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
  
  export default {
    name: "InstructorRead",
    components: {
      mdbContainer,
      mdbRow,
      mdbCol
    },
    data() {
      return {
        results: [],
        filteredResults: [],
        searchQuery: "",
        currentPage: 1,
        perPage: 10
      };
    },
    computed: {
      paginatedData() {
        const start = (this.currentPage - 1) * this.perPage;
        return this.filteredResults.slice(start, start + this.perPage);
      },
      pageCount() {
        return Math.ceil(this.filteredResults.length / this.perPage);
      }
    },
    mounted() {
      this.fetchInstructors();
    },
    methods: {
      async fetchInstructors() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData?.access_token || "";
  
          const res = await axios.get("/master/instructor", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
  
          this.results = Array.isArray(res.data) ? res.data : [];
          this.filteredResults = [...this.results];
        } catch (error) {
          console.error("교관정보 조회 실패:", error);
          this.results = [];
          this.filteredResults = [];
        }
      },
      filterResults() {
        const query = this.searchQuery.trim().toLowerCase();
        if (!query) {
          this.filteredResults = [...this.results];
          return;
        }
        this.filteredResults = this.results.filter((item) =>
          item.name.toLowerCase().includes(query)
        );
        this.currentPage = 1;
      },
      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      },
      nextPage() {
        if (this.currentPage < this.pageCount) this.currentPage++;
      },
      goToRegister() {
        this.$router.push({ name: "master-instructorCreate" });
      },
      goToUpdate(item) {
        this.$router.push({
          name: "master-instructorUpdateDelete",
          query: { data: JSON.stringify(item) }
        });
      }
    }
  };
  </script>
  
<style scoped>
  .main-container {
    padding: 20px;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .search-container {
    padding: 25px;
 
  }
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 오른쪽 정렬 */
  }
  
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px; /* 각 요소 사이에 간격 추가 */
  }
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }

  .btn-search {
    background-color: #e6f2ff;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-right: 20px; /* 검색 버튼과 등록 버튼 사이의 여백 */
    /*cursor: pointer;
     transition: background-color 0.3s ease; */
  }
  .btn-register {
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-left: auto; /* 등록 버튼을 오른쪽 끝으로 정렬 */
    align-self: flex-end; /* 등록 버튼을 아래쪽으로 정렬 */
    }
  
    table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;  
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }
  .alt-row {
    background-color: #f9f9f9;
  }
  .clickable-row {
    cursor: pointer;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
 
</style>  

  